import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Authorization = Node & {
  __typename?: 'Authorization';
  createdAt: Scalars['Int'];
  description: Scalars['String'];
  expiresAt: Scalars['Int'];
  id: Scalars['ID'];
  maxAmount: Scalars['Float'];
  meta?: Maybe<AuthorizationMeta>;
  revokedAt?: Maybe<Scalars['Int']>;
  usedAt?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type AuthorizationMeta = {
  __typename?: 'AuthorizationMeta';
  associatedGasRecord?: Maybe<GasRecord>;
};

export type AuthorizationRequest = {
  expiresAt: Scalars['Int'];
  maxAmount: Scalars['Float'];
  userId: Scalars['String'];
};

export type CardActivity = Node & {
  __typename?: 'CardActivity';
  action: Scalars['String'];
  amount: Scalars['Float'];
  authMethod?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  id: Scalars['ID'];
  merchantCategory?: Maybe<Scalars['String']>;
  merchantCity?: Maybe<Scalars['String']>;
  merchantCountry?: Maybe<Scalars['String']>;
  merchantName?: Maybe<Scalars['String']>;
  merchantPostalCode?: Maybe<Scalars['String']>;
  merchantState?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
  user?: Maybe<User>;
};

export type GasRecord = Node & {
  __typename?: 'GasRecord';
  backfill?: Maybe<GasRecordBackfill>;
  createdAt: Scalars['Int'];
  gasBrand: Scalars['String'];
  gasBuddyStationId?: Maybe<Scalars['String']>;
  gpsLat?: Maybe<Scalars['Float']>;
  gpsLon?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  milesRemaining?: Maybe<Scalars['Int']>;
  odometer: Scalars['Int'];
  priceOfGas: Scalars['Float'];
  tankPercentage?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

export type GasRecordBackfill = {
  __typename?: 'GasRecordBackfill';
  amountPaid: Scalars['Float'];
  createdAt: Scalars['Int'];
  gasFilled: Scalars['Float'];
  milesPerGallon: Scalars['Float'];
};

export type GasRequest = {
  currentOdometer: Scalars['Int'];
  gasBrand: Scalars['String'];
  gasBuddyStationId?: InputMaybe<Scalars['String']>;
  gpsLat?: InputMaybe<Scalars['Float']>;
  gpsLon?: InputMaybe<Scalars['Float']>;
  /** Provide EITHER tankPercentage OR milesRemaining */
  milesRemaining?: InputMaybe<Scalars['Int']>;
  priceOfGas: Scalars['Float'];
  /** Provide EITHER tankPercentage OR milesRemaining */
  tankPercentage?: InputMaybe<Scalars['Int']>;
  vehicleId: Scalars['String'];
};

export type GeneralRecord = Node & {
  __typename?: 'GeneralRecord';
  backfill?: Maybe<GeneralRecordBackfill>;
  createdAt: Scalars['Int'];
  customerName: Scalars['String'];
  gpsLat?: Maybe<Scalars['Float']>;
  gpsLon?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  purchaseDescription: Scalars['String'];
  user?: Maybe<User>;
};

export type GeneralRecordBackfill = {
  __typename?: 'GeneralRecordBackfill';
  amountPaid: Scalars['Float'];
  createdAt: Scalars['Int'];
};

export type GeneralRequest = {
  customerName: Scalars['String'];
  gpsLat?: InputMaybe<Scalars['Float']>;
  gpsLon?: InputMaybe<Scalars['Float']>;
  purchaseDescription: Scalars['String'];
};

export type GenericUpdatePayload = {
  __typename?: 'GenericUpdatePayload';
  successful: Scalars['Boolean'];
};

export type MembershipCard = {
  __typename?: 'MembershipCard';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAuthorization: RequestResponsePayload;
  addMembershipCard: GenericUpdatePayload;
  genAuthToken: Scalars['String'];
  loginCode?: Maybe<Token>;
  loginPin?: Maybe<Token>;
  logout: Scalars['Boolean'];
  removeMembershipCard: GenericUpdatePayload;
  requestGas: RequestResponsePayload;
  requestGeneral: RequestResponsePayload;
  setMyFcmToken: UpdateUserPayload;
  setUserCard: UpdateUserPayload;
  setUserVehicles: UpdateUserPayload;
};


export type MutationAddAuthorizationArgs = {
  authorization: AuthorizationRequest;
};


export type MutationAddMembershipCardArgs = {
  dataUri: Scalars['String'];
  name: Scalars['String'];
};


export type MutationLoginCodeArgs = {
  code: Scalars['String'];
};


export type MutationLoginPinArgs = {
  pin: Scalars['String'];
  preAuthToken: Scalars['String'];
  scopes: Array<Scalars['String']>;
};


export type MutationRemoveMembershipCardArgs = {
  id: Scalars['ID'];
};


export type MutationRequestGasArgs = {
  request: GasRequest;
};


export type MutationRequestGeneralArgs = {
  request: GeneralRequest;
};


export type MutationSetMyFcmTokenArgs = {
  fcmToken: Scalars['String'];
};


export type MutationSetUserCardArgs = {
  cardLast4: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSetUserVehiclesArgs = {
  userId: Scalars['String'];
  vehicleIds: Array<Scalars['String']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type OdometerReading = Node & {
  __typename?: 'OdometerReading';
  createdAt: Scalars['Int'];
  id: Scalars['ID'];
  reading: Scalars['Int'];
  source: Scalars['String'];
  vehicle?: Maybe<Vehicle>;
};

export type Query = {
  __typename?: 'Query';
  allVehicles?: Maybe<Array<Vehicle>>;
  cardActitivity?: Maybe<Array<CardActivity>>;
  gasRecords?: Maybe<Array<GasRecord>>;
  generalRecords?: Maybe<Array<GeneralRecord>>;
  me?: Maybe<User>;
  membershipCards?: Maybe<Array<MembershipCard>>;
  myVehicles?: Maybe<Array<Vehicle>>;
  node?: Maybe<Node>;
  oauthClientId: Scalars['String'];
  users?: Maybe<Array<User>>;
};


export type QueryCardActitivityArgs = {
  filter: QueryFilter;
};


export type QueryGasRecordsArgs = {
  filter: QueryFilter;
};


export type QueryGeneralRecordsArgs = {
  filter: QueryFilter;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryFilter = {
  afterTimestamp?: InputMaybe<Scalars['Int']>;
  beforeTimestamp?: InputMaybe<Scalars['Int']>;
  count?: Scalars['Int'];
  page?: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type RequestResponsePayload = {
  __typename?: 'RequestResponsePayload';
  approved: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  expiresAt: Scalars['Int'];
  scope: Array<Scalars['String']>;
  token: Scalars['String'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  successful: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  assignedVehicles?: Maybe<Array<Maybe<Vehicle>>>;
  cardLast4?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  scopes?: Maybe<Array<Scalars['String']>>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  assignedUsers?: Maybe<Array<User>>;
  currentFuelLevel?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  year: Scalars['String'];
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, name: string, cardLast4?: string | null, assignedVehicles?: Array<{ __typename?: 'Vehicle', id: string } | null> | null }> | null };

export type GetUsersAndVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersAndVehiclesQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, name: string, cardLast4?: string | null, assignedVehicles?: Array<{ __typename?: 'Vehicle', id: string } | null> | null }> | null, allVehicles?: Array<{ __typename?: 'Vehicle', id: string, name: string, make: string, model: string, year: string, registrationNumber: string, currentFuelLevel?: number | null }> | null };

export type AddAuthorizationMutationVariables = Exact<{
  authorization: AuthorizationRequest;
}>;


export type AddAuthorizationMutation = { __typename?: 'Mutation', addAuthorization: { __typename?: 'RequestResponsePayload', approved: boolean, reason: string } };

export type GetGasRecordsQueryVariables = Exact<{
  filter: QueryFilter;
}>;


export type GetGasRecordsQuery = { __typename?: 'Query', gasRecords?: Array<{ __typename?: 'GasRecord', id: string, createdAt: number, odometer: number, milesRemaining?: number | null, tankPercentage?: number | null, priceOfGas: number, gasBuddyStationId?: string | null, gasBrand: string, gpsLat?: number | null, gpsLon?: number | null, user?: { __typename?: 'User', id: string, name: string } | null, vehicle?: { __typename?: 'Vehicle', id: string, name: string, make: string, model: string, year: string, registrationNumber: string } | null, backfill?: { __typename?: 'GasRecordBackfill', createdAt: number, amountPaid: number, gasFilled: number, milesPerGallon: number } | null }> | null };

export type GetGeneralRecordsQueryVariables = Exact<{
  filter: QueryFilter;
}>;


export type GetGeneralRecordsQuery = { __typename?: 'Query', generalRecords?: Array<{ __typename?: 'GeneralRecord', id: string, createdAt: number, customerName: string, purchaseDescription: string, gpsLat?: number | null, gpsLon?: number | null, user?: { __typename?: 'User', id: string, name: string } | null, backfill?: { __typename?: 'GeneralRecordBackfill', createdAt: number, amountPaid: number } | null }> | null };

export type GetClientIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientIdQuery = { __typename?: 'Query', oauthClientId: string };

export type LoginCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type LoginCodeMutation = { __typename?: 'Mutation', loginCode?: { __typename?: 'Token', token: string, expiresAt: number, scope: Array<string> } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type SetUserCardMutationVariables = Exact<{
  userId: Scalars['String'];
  cardLast4: Scalars['String'];
}>;


export type SetUserCardMutation = { __typename?: 'Mutation', setUserCard: { __typename?: 'UpdateUserPayload', successful: boolean, user?: { __typename?: 'User', id: string } | null } };

export type SetUserVehiclesMutationVariables = Exact<{
  userId: Scalars['String'];
  vehicleIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type SetUserVehiclesMutation = { __typename?: 'Mutation', setUserVehicles: { __typename?: 'UpdateUserPayload', successful: boolean, user?: { __typename?: 'User', id: string } | null } };

export type AddMembershipCardMutationVariables = Exact<{
  name: Scalars['String'];
  dataUri: Scalars['String'];
}>;


export type AddMembershipCardMutation = { __typename?: 'Mutation', addMembershipCard: { __typename?: 'GenericUpdatePayload', successful: boolean } };

export type GetMembershipCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembershipCardsQuery = { __typename?: 'Query', membershipCards?: Array<{ __typename?: 'MembershipCard', id: string, imageUrl: string, name: string }> | null };

export type RemoveMembershipCardMutationVariables = Exact<{
  removeMembershipCardId: Scalars['ID'];
}>;


export type RemoveMembershipCardMutation = { __typename?: 'Mutation', removeMembershipCard: { __typename?: 'GenericUpdatePayload', successful: boolean } };


export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    name
    cardLast4
    assignedVehicles {
      id
    }
  }
}
    `;

export function useGetUsersQuery(options?: Omit<Urql.UseQueryArgs<GetUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersQuery>({ query: GetUsersDocument, ...options });
};
export const GetUsersAndVehiclesDocument = gql`
    query GetUsersAndVehicles {
  users {
    id
    name
    cardLast4
    assignedVehicles {
      id
    }
  }
  allVehicles {
    id
    name
    make
    model
    year
    registrationNumber
    currentFuelLevel
  }
}
    `;

export function useGetUsersAndVehiclesQuery(options?: Omit<Urql.UseQueryArgs<GetUsersAndVehiclesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersAndVehiclesQuery>({ query: GetUsersAndVehiclesDocument, ...options });
};
export const AddAuthorizationDocument = gql`
    mutation AddAuthorization($authorization: AuthorizationRequest!) {
  addAuthorization(authorization: $authorization) {
    approved
    reason
  }
}
    `;

export function useAddAuthorizationMutation() {
  return Urql.useMutation<AddAuthorizationMutation, AddAuthorizationMutationVariables>(AddAuthorizationDocument);
};
export const GetGasRecordsDocument = gql`
    query GetGasRecords($filter: QueryFilter!) {
  gasRecords(filter: $filter) {
    id
    createdAt
    user {
      id
      name
    }
    vehicle {
      id
      name
      make
      model
      year
      registrationNumber
    }
    odometer
    milesRemaining
    tankPercentage
    priceOfGas
    gasBuddyStationId
    gasBrand
    gpsLat
    gpsLon
    backfill {
      createdAt
      amountPaid
      gasFilled
      milesPerGallon
    }
  }
}
    `;

export function useGetGasRecordsQuery(options: Omit<Urql.UseQueryArgs<GetGasRecordsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGasRecordsQuery>({ query: GetGasRecordsDocument, ...options });
};
export const GetGeneralRecordsDocument = gql`
    query GetGeneralRecords($filter: QueryFilter!) {
  generalRecords(filter: $filter) {
    id
    createdAt
    user {
      id
      name
    }
    customerName
    purchaseDescription
    gpsLat
    gpsLon
    backfill {
      createdAt
      amountPaid
    }
  }
}
    `;

export function useGetGeneralRecordsQuery(options: Omit<Urql.UseQueryArgs<GetGeneralRecordsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGeneralRecordsQuery>({ query: GetGeneralRecordsDocument, ...options });
};
export const GetClientIdDocument = gql`
    query GetClientId {
  oauthClientId
}
    `;

export function useGetClientIdQuery(options?: Omit<Urql.UseQueryArgs<GetClientIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetClientIdQuery>({ query: GetClientIdDocument, ...options });
};
export const LoginCodeDocument = gql`
    mutation LoginCode($code: String!) {
  loginCode(code: $code) {
    token
    expiresAt
    scope
  }
}
    `;

export function useLoginCodeMutation() {
  return Urql.useMutation<LoginCodeMutation, LoginCodeMutationVariables>(LoginCodeDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const SetUserCardDocument = gql`
    mutation SetUserCard($userId: String!, $cardLast4: String!) {
  setUserCard(userId: $userId, cardLast4: $cardLast4) {
    successful
    user {
      id
    }
  }
}
    `;

export function useSetUserCardMutation() {
  return Urql.useMutation<SetUserCardMutation, SetUserCardMutationVariables>(SetUserCardDocument);
};
export const SetUserVehiclesDocument = gql`
    mutation SetUserVehicles($userId: String!, $vehicleIds: [String!]!) {
  setUserVehicles(userId: $userId, vehicleIds: $vehicleIds) {
    successful
    user {
      id
    }
  }
}
    `;

export function useSetUserVehiclesMutation() {
  return Urql.useMutation<SetUserVehiclesMutation, SetUserVehiclesMutationVariables>(SetUserVehiclesDocument);
};
export const AddMembershipCardDocument = gql`
    mutation AddMembershipCard($name: String!, $dataUri: String!) {
  addMembershipCard(name: $name, dataUri: $dataUri) {
    successful
  }
}
    `;

export function useAddMembershipCardMutation() {
  return Urql.useMutation<AddMembershipCardMutation, AddMembershipCardMutationVariables>(AddMembershipCardDocument);
};
export const GetMembershipCardsDocument = gql`
    query GetMembershipCards {
  membershipCards {
    id
    imageUrl
    name
  }
}
    `;

export function useGetMembershipCardsQuery(options?: Omit<Urql.UseQueryArgs<GetMembershipCardsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMembershipCardsQuery>({ query: GetMembershipCardsDocument, ...options });
};
export const RemoveMembershipCardDocument = gql`
    mutation RemoveMembershipCard($removeMembershipCardId: ID!) {
  removeMembershipCard(id: $removeMembershipCardId) {
    successful
  }
}
    `;

export function useRemoveMembershipCardMutation() {
  return Urql.useMutation<RemoveMembershipCardMutation, RemoveMembershipCardMutationVariables>(RemoveMembershipCardDocument);
};