import { Button, Card, CardContent, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar, styled, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useAddAuthorizationMutation, useGetUsersQuery } from "../../generated/graphql";

const MainForm = styled("form")`
    display: flex;
    flex-direction: column;
`

export default function AddPreapprovalPage() {
    const [user, setUser] = useState<string | null>(null)
    const [maxAmountInput, setMaxAmountInput] = useState<string>("")
    const [expiryTime, setExpiryTime] = useState<number>(60 * 15)
    const [{ data: usersData, fetching }] = useGetUsersQuery()
    const [addAuthorizationResult, addAuthorization] = useAddAuthorizationMutation()
    const [toastMessage, setToastMessage] = useState<string | null>(null)


    const submit = async () => {
        if (user == null) {
            setToastMessage("User must be selected")
            return
        }

        const maxAmount = parseFloat(maxAmountInput)
        if (isNaN(maxAmount)) {
            setToastMessage("Max Amount must be a number")
            return
        }

        const r = await addAuthorization({
            authorization: {
                expiresAt: DateTime.now().toUnixInteger() + expiryTime,
                maxAmount,
                userId: user
            }
        })
        if (r.data?.addAuthorization.approved) {
            setToastMessage("Authorization added")
        } else {
            setToastMessage("Authorization failed: " + r.data?.addAuthorization.reason)
        }
        setUser(null)
        setExpiryTime(60 * 15)
        setMaxAmountInput("")
    }

    return (
        <div>
            {(fetching || addAuthorizationResult.fetching) && (<LinearProgress />)}

            <Card>
                <CardContent>
                    <Typography variant="h4">Add Preapproval</Typography>
                    <MainForm onSubmit={e => { e.preventDefault(); submit() }}>
                        <FormControl style={{ width: "200px", marginTop: "30px" }}>
                            <InputLabel>User</InputLabel>
                            <Select label="User" value={user} onChange={e => setUser(e.target.value)}>
                                {usersData?.users?.map(u => (
                                    <MenuItem value={u.id}>{u.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: "200px", marginTop: "30px" }}>
                            <InputLabel>Max Amount</InputLabel>
                            <OutlinedInput
                                label="Max Amount"
                                type="number"
                                value={maxAmountInput}
                                onChange={e => setMaxAmountInput(e.target.value)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl style={{ width: "200px", marginTop: "30px" }}>
                            <InputLabel>Expires In</InputLabel>
                            <Select label="Expires In" value={expiryTime} onChange={e => setExpiryTime(e.target.value as number)}>
                                <MenuItem value={60 * 15}>15 Minutes</MenuItem>
                                <MenuItem value={60 * 60}>1 Hour</MenuItem>
                                <MenuItem value={60 * 60 * 3}>3 Hours</MenuItem>
                                <MenuItem value={60 * 60 * 6}>6 Hours</MenuItem>
                                <MenuItem value={60 * 60 * 12}>12 Hours</MenuItem>
                                <MenuItem value={60 * 60 * 24}>24 Hours</MenuItem>
                                <MenuItem value={60 * 60 * 24 * 2}>2 Days</MenuItem>
                                <MenuItem value={60 * 60 * 24 * 3}>3 Days</MenuItem>
                                <MenuItem value={60 * 60 * 24 * 7}>7 Days</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: "200px", marginTop: "30px" }}>
                            <Button type="submit" disabled={addAuthorizationResult.fetching} variant="contained">Submit</Button>
                        </FormControl>
                    </MainForm>
                </CardContent>
            </Card>

            <Snackbar
                open={toastMessage != null}
                onClose={() => setToastMessage(null)}
                message={toastMessage}
                autoHideDuration={6000}
            />
        </div>
    )
}