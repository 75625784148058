import { Typography } from '@mui/material';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopBar from './appbar/TopBar';
import AddPreapprovalPage from './components/AddPreapprovalPage';
import GasRequestsPage from './components/GasRequestsPage';
import GeneralRequestsPage from './components/GeneralRequestsPage';
import ManageCardsPage from './components/ManageCardsPage';
import MembershipCardsPage from './components/MembershipCardsPage';

export default function Entrypoint(props: { logout: () => void }) {
    return (
        <div>
            <TopBar logout={props.logout}>
                <Routes>
                    <Route path="/dashboard" element={<Typography variant="h3" align="center">Select a tab on the left</Typography>} />
                    <Route path="/requests/gas" element={<GasRequestsPage />} />
                    <Route path="/requests/general" element={<GeneralRequestsPage />} />
                    <Route path="/manage" element={<ManageCardsPage />} />
                    <Route path="/preapprove" element={<AddPreapprovalPage />} />\
                    <Route path="/membership-cards" element={<MembershipCardsPage />} />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
            </TopBar>
        </div>
    )
}
