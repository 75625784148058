import { Box, Card, CardContent, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useClient } from "urql";
import { SetUserCardDocument, SetUserCardMutation, SetUserCardMutationVariables, SetUserVehiclesDocument, SetUserVehiclesMutation, SetUserVehiclesMutationVariables, useGetUsersAndVehiclesQuery } from "../../generated/graphql";
import { arraysEqual } from "../../util";

const UsersHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const FieldContainer = styled("div")`
    margin-top: 20px;
`

export default function ManageCardsPage() {
    const client = useClient()
    const [{ data, fetching }] = useGetUsersAndVehiclesQuery()
    const [saved, setSaved] = useState(true)
    const [alteredCards, setAlteredCards] = useState<{ [userId: string]: string }>({})
    const [alteredVehicles, setAlteredVehicles] = useState<{ [userId: string]: string[] }>({})

    useEffect(() => {
        const timerId = setTimeout(async () => {
            for (const userId of Object.keys(alteredCards)) {
                await client.mutation<SetUserCardMutation, SetUserCardMutationVariables>(SetUserCardDocument, {
                    userId,
                    cardLast4: alteredCards[userId]
                }).toPromise()
            }
            for (const userId of Object.keys(alteredVehicles)) {
                await client.mutation<SetUserVehiclesMutation, SetUserVehiclesMutationVariables>(SetUserVehiclesDocument, {
                    userId,
                    vehicleIds: alteredVehicles[userId]
                }).toPromise()
            }
        }, 1500)
        return () => {
            clearTimeout(timerId)
        }
    })

    for (const userId of Object.keys(alteredCards)) {
        if (alteredCards[userId] === (data?.users?.find(u => u.id === userId)?.cardLast4 ?? "")) {
            const reconsiled = { ...alteredCards }
            delete reconsiled[userId]
            setAlteredCards(reconsiled)
            break
        }
    }

    for (const userId of Object.keys(alteredVehicles)) {
        if (arraysEqual(alteredVehicles[userId], data?.users?.find(u => u.id === userId)?.assignedVehicles?.filter(v => v != null).map(v => v!.id) ?? [])) {
            const reconsiled = { ...alteredVehicles }
            delete reconsiled[userId]
            setAlteredVehicles(reconsiled)
            break
        }
    }

    if (!saved && Object.keys(alteredCards).length === 0 && Object.keys(alteredVehicles).length === 0) {
        setSaved(true)
    }

    const getCardLast4 = (userId: string): string => {
        if (alteredCards[userId] != null) {
            return alteredCards[userId]
        }
        return data?.users?.find(u => u.id === userId)?.cardLast4 ?? ""
    }

    const setCardLast4Entry = (userId: string, entry: string) => {
        setAlteredCards({
            ...alteredCards,
            [userId]: entry
        })
        setSaved(false)
    }

    const getVehicles = (userId: string): string[] => {
        if (alteredVehicles[userId] != null) {
            return alteredVehicles[userId]
        }
        return data?.users?.find(u => u.id === userId)?.assignedVehicles?.filter(v => v != null).map(v => v!.id) ?? []
    }

    const setVehiclesEntry = (userId: string, entry: string[]) => {
        setAlteredVehicles({
            ...alteredVehicles,
            [userId]: entry
        })
        setSaved(false)
    }

    return (
        <div>
            {(fetching) && (<LinearProgress />)}

            {saved && !fetching && <Typography variant="overline">SAVED</Typography>}
            {!saved && <Typography variant="overline" color="red">NOT SAVED</Typography>}
            <UsersHolder>
                {data?.users?.sort((a, b) => a.name.localeCompare(b.name))?.map(u => (
                    <Card variant="outlined" key={u.id}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {u.name}
                            </Typography>
                            <FieldContainer>
                                <TextField label="Card Last 4" variant="outlined" value={getCardLast4(u.id)} onChange={e => setCardLast4Entry(u.id, e.target.value)} />
                            </FieldContainer>
                            <FieldContainer>
                                <FormControl fullWidth>
                                    <InputLabel>Vehicles</InputLabel>
                                    <Select
                                        multiple
                                        value={getVehicles(u.id)}
                                        input={<OutlinedInput label="Vehicles" />}
                                        renderValue={(selected: string[]) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {data.allVehicles?.filter(v => selected.includes(v.id)).map(v => (
                                                    <Chip key={v.id} label={[v.name, v.make, v.model, v.year, v.registrationNumber].join(" ")} />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(e) => setVehiclesEntry(u.id, e.target.value as string[])}
                                    >
                                        {data?.allVehicles?.map(v => (
                                            <MenuItem value={v.id}>
                                                <Checkbox checked={(getVehicles(u.id)?.indexOf(v.id) ?? -1) > -1} />
                                                <ListItemText primary={[v.name, v.make, v.model, v.year, v.registrationNumber].join(" ")} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </FieldContainer>
                        </CardContent>
                    </Card>
                ))}
            </UsersHolder>

        </div>
    )
}