import { Typography } from '@mui/material'
import { useLocation } from 'react-router'
import { useLoginCodeMutation } from '../../generated/graphql'
import styles from "./index.module.css"

export default function OauthLogin({ setToken }: { setToken: (token: string, scope: string[]) => void }) {
    const location = useLocation()
    const [loginCodeResult, loginCode] = useLoginCodeMutation()

    let message = "An unknown error occurred"
    const query = new URLSearchParams(location.search)
    if (query.get("error") === "access_denied") {
        message = "You do not have access to this page"
    } else if (query.get("code") != null) {
        message = "Loading..."
        if (!loginCodeResult.fetching) {
            loginCode({
                code: query.get("code")!
            })
        }
    }

    if (loginCodeResult.data != null && loginCodeResult.data.loginCode?.token != null) {
        setToken(loginCodeResult.data.loginCode.token, loginCodeResult.data.loginCode.scope)
    }

    return (
        <div className={styles.login_page}>
            <img src="/img/logo.png" alt="" />
            <Typography variant="h2" align="center">Card</Typography>
            <Typography>{message}</Typography>
        </div>
    )
}
