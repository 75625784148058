import { Button, ButtonGroup, Card, CardContent, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Select, styled, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { DateTime } from "luxon"
import { useState } from "react"
import { QueryFilter, useGetGeneralRecordsQuery, useGetUsersAndVehiclesQuery } from "../../generated/graphql"

const RecordsHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const RecordDetailsHolder = styled("div")`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`

const RecordDetail = styled("div")`
    margin-left: 30px;
`

const Filters = styled("div")`
    margin: 20px;
`

const PageControls = styled("div")`
    margin: 20px;
`

export default function GeneralRequestsPage() {
    const [filter, setFilter] = useState<QueryFilter>({
        afterTimestamp: null,
        beforeTimestamp: null,
        userId: null,
        vehicleId: null,
        count: 20,
        page: 0,
    })
    const [{ data: generalRecordsData, fetching: fetchingGeneralRecords }] = useGetGeneralRecordsQuery({
        variables: {
            filter: filter
        }
    })
    const [{ data: preemptiveNextPageGeneralRecordsData }] = useGetGeneralRecordsQuery({
        variables: {
            filter: { ...filter, page: (filter.page ?? 0) + 1 }
        }
    })
    const [{ data: usersAndVehiclesData, fetching: fetchingUsersAndVehicles }] = useGetUsersAndVehiclesQuery()

    return (
        <div>
            {(fetchingGeneralRecords || fetchingUsersAndVehicles) && (<LinearProgress />)}

            <Filters>
                <p>Filter</p>
                <FormControl style={{ width: "200px", marginRight: "10px" }}>
                    <InputLabel>User</InputLabel>
                    <Select value={filter.userId} label="User" onChange={e => setFilter({ ...filter, userId: e.target.value })}>
                        <MenuItem value={null as any} />
                        {usersAndVehiclesData?.users?.map(u => (
                            <MenuItem value={u.id}>{u.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ width: "200px", marginRight: "10px" }}>
                    <DatePicker
                        label="From"
                        inputFormat="MM/dd/yyyy"
                        value={filter.afterTimestamp ? DateTime.fromSeconds(filter.afterTimestamp) : null}
                        onChange={(t: DateTime | null) => setFilter({ ...filter, afterTimestamp: t?.startOf('day')?.toUnixInteger() })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl style={{ width: "200px", marginRight: "10px" }}>
                    <DatePicker
                        label="To"
                        inputFormat="MM/dd/yyyy"
                        value={filter.beforeTimestamp ? DateTime.fromSeconds(filter.beforeTimestamp) : null}
                        onChange={(t: DateTime | null) => setFilter({ ...filter, beforeTimestamp: t?.endOf('day')?.toUnixInteger() })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
            </Filters>

            <PageControls>
                <ButtonGroup variant="outlined">
                    <Button
                        disabled={filter.page === 0}
                        onClick={() => setFilter({ ...filter, page: (filter.page ?? 0) - 1 })}>
                        Previous
                    </Button>
                    <Button
                        disabled={(preemptiveNextPageGeneralRecordsData?.generalRecords?.length ?? 0) === 0}
                        onClick={() => setFilter({ ...filter, page: (filter.page ?? 0) + 1 })}>
                        Next
                    </Button>
                </ButtonGroup>
            </PageControls>

            <RecordsHolder>
                {generalRecordsData?.generalRecords?.map(record => (
                    <Card variant="outlined">
                        <CardContent>
                            <RecordDetailsHolder>
                                <RecordDetail>
                                    <Typography>
                                        {record.user?.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        User
                                    </Typography>
                                </RecordDetail>
                                <RecordDetail>
                                    <Typography>
                                        {new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(record.createdAt * 1000))}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        Timestamp
                                    </Typography>
                                </RecordDetail>
                                <RecordDetail>
                                    <Typography>
                                        {record.customerName}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        Customer
                                    </Typography>
                                </RecordDetail>
                                <RecordDetail>
                                    <Typography>
                                        {record.purchaseDescription}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        Purchase Description
                                    </Typography>
                                </RecordDetail>
                            </RecordDetailsHolder>
                            {record.backfill && (
                                <>
                                    <Divider style={{ margin: "20px" }} />
                                    <RecordDetailsHolder>
                                        <RecordDetail>
                                            <Typography>
                                                ${record.backfill.amountPaid}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                Amount Paid
                                            </Typography>
                                        </RecordDetail>
                                    </RecordDetailsHolder>
                                </>
                            )}
                            <RecordDetailsHolder>
                                {record.gpsLat && record.gpsLon && (<small><a href={`https://www.google.com/maps/place/${record.gpsLat},${record.gpsLon}`} target="_blank" rel="noreferrer">View GPS</a></small>)}
                            </RecordDetailsHolder>
                        </CardContent>
                    </Card>
                ))}
            </RecordsHolder>

            {(generalRecordsData?.generalRecords?.length ?? 0) > 0 && (
                <PageControls>
                    <ButtonGroup variant="outlined">
                        <Button
                            disabled={filter.page === 0}
                            onClick={() => setFilter({ ...filter, page: (filter.page ?? 0) - 1 })}>
                            Previous
                        </Button>
                        <Button
                            disabled={(preemptiveNextPageGeneralRecordsData?.generalRecords?.length ?? 0) === 0}
                            onClick={() => setFilter({ ...filter, page: (filter.page ?? 0) + 1 })}>
                            Next
                        </Button>
                    </ButtonGroup>
                </PageControls>
            )}
        </div>
    )
}