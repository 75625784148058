import { ListItemButton } from '@mui/material'
import { Link, useMatch } from 'react-router-dom'

export default function Tab(props: {name: string, path: string}) {


    return (
        <ListItemButton selected={useMatch(props.path) != null} component={Link} to={props.path}>
            {props.name}
        </ListItemButton>
    )
}