import React from "react"
import theme from "./theme"
import Login from "./components/Login";
import Entrypoint from "./Entrypoint";
import { CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import { cacheExchange, CombinedError, createClient, dedupExchange, errorExchange, fetchExchange, getOperationName, Operation, Provider as UrqlProvider } from "urql";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { BrowserRouter } from "react-router-dom";
import { LogoutDocument } from "./generated/graphql";
import { LocalizationProvider } from "@mui/x-date-pickers";

class App extends React.Component<{}, { token: string, scope: string[], networkError: boolean }> {
    constructor(props: any) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('token') ?? '',
            scope: window.localStorage.getItem('scope')?.split(' ') ?? [],
            networkError: false
        }
    }

    newClient = () => createClient({
        url: 'https://card.a-qualitypools.net/api/graphql',
        fetchOptions: () => {
            if (this.hasToken()) {
                return { headers: { authorization: `User ${this.state.token}` } }
            }
            return {}
        },
        exchanges: [
            dedupExchange,
            cacheExchange,
            errorExchange({
                onError: (error: CombinedError, operation: Operation) => {
                    if (error.graphQLErrors) {
                        error.graphQLErrors.forEach(({ message, locations, path }) => {
                            if (message === "not enough permissions" && getOperationName(operation.query) !== "Logout") {
                                this.logout()
                            } else {
                                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                            }
                        })
                    }
                    if (error.networkError) {
                        console.error(`[Network error]: ${error.networkError}`)
                        this.setState({ networkError: true })
                    }
                },
            }),
            fetchExchange
        ]
    })

    client = this.newClient()

    setToken = (token: string, scope: string[]) => {
        this.setState({
            token: token,
            scope: scope
        })
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('scope', scope.join(' '))
    }

    hasToken = () => this.state.token !== ''

    logout = async () => {
        try {
            await this.client.mutation(LogoutDocument).toPromise()
        } finally {
            this.client = this.newClient()
            this.setToken('', [])
            window.localStorage.clear()
        }

    }


    render() {
        const Base = (props: any) => (
            <UrqlProvider value={this.client}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            {props.children}
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={this.state.networkError}
                                autoHideDuration={6000}
                                onClose={() => this.setState({ networkError: false })}
                                message="A network error ocurred. Please try again later"
                            />
                        </ThemeProvider>
                        <CssBaseline />
                    </BrowserRouter>
                </LocalizationProvider>
            </UrqlProvider>
        )

        if (!this.hasToken()) {
            return (
                <Base>
                    <Login setToken={this.setToken} />
                </Base>
            )
        }

        return (
            <Base>
                <Entrypoint logout={this.logout} />
            </Base>
        )
    }

}

export default App;
