import { Button, Card, CardContent, FormControl, LinearProgress, TextField, Typography, styled } from "@mui/material"
import { useAddMembershipCardMutation, useGetMembershipCardsQuery, useRemoveMembershipCardMutation } from "../../generated/graphql"
import { useState } from "react";

const RecordsHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function MembershipCardsPage() {
    const [{ data: membershipCardsData, fetching: fetchingMembershipCards }, refetchMembershipCards] = useGetMembershipCardsQuery()
    const addMembershipCard = useAddMembershipCardMutation()[1]
    const removeMembershipCard = useRemoveMembershipCardMutation()[1]
    const [uploadedImageB64, setUploadedImageB64] = useState<string>()
    const [uploadedCardName, setUploadedCardName] = useState<string>("")

    const addCard = async () => {
        const result = await addMembershipCard({ name: uploadedCardName, dataUri: uploadedImageB64! })
        if (result.data?.addMembershipCard.successful === true) {
            setUploadedCardName("")
            setUploadedImageB64(undefined)
            refetchMembershipCards({ requestPolicy: "network-only" })
        }
    }

    const removeCard = async (id: string) => {
        const result = await removeMembershipCard({ removeMembershipCardId: id })
        if (result.data?.removeMembershipCard.successful === true) {
            refetchMembershipCards({ requestPolicy: "network-only" })
        }
    }

    return (
        <div>
            {(fetchingMembershipCards) && (<LinearProgress />)}

            <Card style={{ marginBottom: "30px" }}>
                <CardContent>
                    <Typography variant="h6">Add Membership Card</Typography>
                    <form style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} onSubmit={e => { e.preventDefault(); addCard() }}>
                        <FormControl style={{ margin: "30px" }}>
                            <Button component="label" variant="contained">
                                Upload file
                                <VisuallyHiddenInput type="file" onChange={(e) => {
                                    if (e.target.files?.item(0) != null) {
                                        const reader = new FileReader()
                                        reader.addEventListener("load", () => setUploadedImageB64(reader.result?.toString()))
                                        reader.readAsDataURL(e.target.files.item(0)!)
                                    }
                                }} />
                            </Button>
                        </FormControl>
                        {uploadedImageB64 != null && (
                            <>
                                <img src={uploadedImageB64} style={{ width: "300px", marginBottom: "30px" }} alt="New membership card" />
                                <TextField label="Name" value={uploadedCardName} onChange={(e) => setUploadedCardName(e.target.value)} />
                                <FormControl style={{ margin: "30px" }}>
                                    <Button type="submit" disabled={uploadedCardName.length === 0} variant="contained" color="success">Add</Button>
                                </FormControl>
                            </>
                        )}
                    </form>
                </CardContent>
            </Card>

            <RecordsHolder>
                {membershipCardsData?.membershipCards?.map(card => (
                    <Card variant="outlined" key={card.id}>
                        <CardContent>
                            <Typography variant="h6">{card.name}</Typography>
                            <img src={card.imageUrl} style={{ width: "300px" }} alt={`${card.name}`} />
                            <div>
                                <Button color="error" variant="outlined" onClick={() => removeCard(card.id)}>Delete</Button>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </RecordsHolder>
        </div>
    )
}