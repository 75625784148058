import { useLocation } from 'react-router'
import OauthLogin from './OauthLogin'
import styles from "./index.module.css"
import { Button, Typography } from '@mui/material'
import { useGetClientIdQuery } from '../../generated/graphql'

const allScopes = [
    "cards.authorize-others-gas",
    "cards.authorize-others-general",
    "cards.manage-cards",
    "cards.view-reports",
    "cards.view-membership-cards"
]

export default function Login({ setToken }: { setToken: (token: string, scope: string[]) => void }) {
    const [{ data }] = useGetClientIdQuery()
    const location = useLocation()

    const redirectUrl = new URL("/oauthlogin", window.location.origin)
    const oauthUrl = new URL("https://sso.a-qualitypools.net/oauth/authorize")
    oauthUrl.searchParams.append("response_type", "code")
    oauthUrl.searchParams.append("client_id", data?.oauthClientId ?? "")
    oauthUrl.searchParams.append("scope", allScopes.join(' '))
    oauthUrl.searchParams.append("redirect_uri", redirectUrl.toString())

    if (location.pathname === "/oauthlogin") {
        return (
            <OauthLogin setToken={setToken} />
        )
    }

    return (
        <div className={styles.login_page}>
            <img src="/img/logo.png" alt="" />
            <Typography variant="h2" align="center">A-Quality Card</Typography>
            <Button size="large" variant="contained" color="primary" component="a" href={oauthUrl.toString()} disabled={data == null}>Login</Button>
        </div>
    )
}
